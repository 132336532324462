// Badges
.fw-badge {
  display: inline-flex;
  align-items: center;
  padding: 2px 12px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 20px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba($purple-3, 0.1);
  }

  // Color variants
  &.bg-primary {
    background: rgba($primary, 0.1) !important;
    color: $primary;
    border: 1px solid rgba($primary, 0.2);
  }

  &.bg-secondary {
    background: rgba($secondary, 0.1) !important;
    color: $secondary;
    border: 1px solid rgba($secondary, 0.2);
  }

  &.bg-success {
    background: rgba($success, 0.1) !important;
    color: $success;
    border: 1px solid rgba($success, 0.2);
  }

  &.bg-info {
    background: rgba($info, 0.1) !important;
    color: $info;
    border: 1px solid rgba($info, 0.2);
  }

  &.bg-warning {
    background: rgba($warning, 0.1) !important;
    color: $warning;
    border: 1px solid rgba($warning, 0.2);
  }

  &.bg-danger {
    background: rgba($danger, 0.1) !important;
    color: $danger;
    border: 1px solid rgba($danger, 0.2);
  }

  // With icon variant
  &.with-icon {
    .material-symbols-rounded {
      font-size: 14px;
      font-variation-settings: 'FILL' 1;
      margin-right: 4px;
    }
  }

  // Status variants
  &.status-badge {
    padding: 8px 12px;
    border-radius: 20px;
    background: transparent;
    border: 1px solid transparent;

    .material-symbols-rounded {
      font-size: 16px;
      font-variation-settings: 'FILL' 1;
    }

    &.pending {
      background: rgba($warning, 0.1);
      color: $warning;
      border-color: rgba($warning, 0.2);

      .material-symbols-rounded {
        color: $warning;
      }
    }

    &.processing {
      background: rgba($info, 0.1);
      color: $info;
      border-color: rgba($info, 0.2);

      .material-symbols-rounded {
        color: $info;
        animation: spin 2s linear infinite;
      }
    }

    &.completed {
      background: rgba($success, 0.1);
      color: $success;
      border-color: rgba($success, 0.2);

      .material-symbols-rounded {
        color: $success;
      }
    }

    &.error {
      background: rgba($danger, 0.1);
      color: $danger;
      border-color: rgba($danger, 0.2);

      .material-symbols-rounded {
        color: $danger;
      }
    }
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
