// Estilos específicos para modais
.service-confirmation-modal {
  // Remove padding padrão do Material
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 0 !important;
  }

  // Ajusta o container do modal
  .mat-mdc-dialog-container {
    padding: 0;
    overflow: hidden;
  }

  // Ajusta o conteúdo do modal
  .mat-mdc-dialog-content {
    margin: 0;
    padding: 0;
    max-height: none;
  }
}
