// Base button styles
.fw-btn {
  display: inline-flex !important;
  align-items: flex-start;
  justify-content: center;
  gap: map-get($spacing, 'xs');
  padding: map-get($spacing, 'sm') map-get($spacing, 'md');
  font-size: map-get($font-sizes, 'sm');
  font-weight: map-get($font-weights, 'semibold');
  border-radius: map-get($border-radius, 'md');
  border: none;
  cursor: pointer;
  transition: all $transition-duration $transition-timing;
  position: relative;
  overflow: hidden;
  text-decoration: none;

  .material-symbols-rounded {
    font-size: map-get($font-sizes, 'lg');
    transition: transform $transition-duration $transition-timing;
  }

  // Primary Button
  &.btn-first {
    background: linear-gradient(135deg, $primary, darken($primary, 10%));
    color: $white;
    box-shadow: map-get($box-shadow, 'sm');
    span {
      color: $white;
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: map-get($box-shadow, 'md');
      background: linear-gradient(135deg, lighten($primary, 5%), $primary);

      .material-symbols-rounded {
        transform: scale(1.1);
      }
    }

    &:active {
      transform: translateY(1px);
      box-shadow: map-get($box-shadow, 'sm');
    }

    &:disabled {
      background: rgba($primary, 0.5);
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }
  }

  // Secondary Button
  &.btn-second {
    background: linear-gradient(135deg, $secondary, darken($secondary, 10%));
    color: $white;
    box-shadow: map-get($box-shadow, 'sm');
    span {
      color: $white;
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: map-get($box-shadow, 'md');
      background: linear-gradient(135deg, lighten($secondary, 5%), $secondary);

      .material-symbols-rounded {
        transform: scale(1.1);
      }
    }

    &:active {
      transform: translateY(1px);
      box-shadow: map-get($box-shadow, 'sm');
    }

    &:disabled {
      background: rgba($secondary, 0.5);
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }
  }

  // Success Button
  &.btn-success {
    background: linear-gradient(135deg, $success, darken($success, 10%));
    color: $white;
    box-shadow: map-get($box-shadow, 'sm');
    span {
      color: $white;
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: map-get($box-shadow, 'md');
      background: linear-gradient(135deg, lighten($success, 5%), $success);

      .material-symbols-rounded {
        transform: scale(1.1);
      }
    }

    &:active {
      transform: translateY(1px);
      box-shadow: map-get($box-shadow, 'sm');
    }

    &:disabled {
      background: rgba($success, 0.5);
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }
  }

  // Ghost Button
  &.btn-ghost {
    background: transparent;
    color: $gray-6;
    span {
      color: $gray-6;
    }

    &:hover {
      background: rgba($purple-3, 0.04);
      color: $gray-7;
      transform: translateY(-1px);

      .material-symbols-rounded {
        transform: scale(1.1);
      }
    }

    &:active {
      transform: translateY(1px);
    }

    &.primary {
      color: $primary;
      span {
        color: $primary;
      }

      &:hover {
        background: rgba($primary, 0.04);
        color: darken($primary, 10%);
      }
    }

    &.danger {
      color: $danger;
      span {
        color: $danger;
      }

      &:hover {
        background: rgba($danger, 0.04);
        color: darken($danger, 10%);
      }
    }

    &:disabled {
      color: $gray-4;
      cursor: not-allowed;
      transform: none;

      .material-symbols-rounded {
        transform: none;
      }
    }

    &.success {
      color: $success;
      span {
        color: $success;
      }

      &:hover {
        background: rgba($success, 0.04);
        color: darken($success, 10%);
      }
    }
  }

  // Text Button
  &.btn-text {
    background: rgba($purple-3, 0.04);
    color: $gray-6;
    span {
      color: $gray-6;
    }

    .material-symbols-rounded {
      font-size: 18px;
      transition: all 0.2s ease;
    }

    &:hover {
      background: rgba($purple-3, 0.08);
      color: $gray-7;
      transform: translateY(-1px);

      .material-symbols-rounded {
        transform: scale(1.1);
      }
    }

    &:active {
      background: rgba($purple-3, 0.12);
      transform: translateY(1px);
    }

    &.primary {
      background: rgba($primary, 0.08);
      color: $primary;
      span {
        color: $primary;
      }

      &:hover {
        background: rgba($primary, 0.08);
        color: darken($primary, 10%);
        span {
          color: darken($primary, 10%);
        }
      }

      &:active {
        background: rgba($primary, 0.12);
      }
    }

    &.secondary {
      background: rgba($secondary, 0.04);
      color: $secondary;
      span {
        color: $secondary;
      }

      &:hover {
        background: rgba($secondary, 0.08);
        color: darken($secondary, 10%);
        span {
          color: darken($secondary, 10%);
        }
      }

      &:active {
        background: rgba($secondary, 0.12);
      }
    }

    &.danger {
      background: rgba($danger, 0.04);
      color: $danger;
      span {
        color: $danger;
      }

      &:hover {
        background: rgba($danger, 0.08);
        color: darken($danger, 10%);
        span {
          color: darken($danger, 10%);
        }
      }

      &:active {
        background: rgba($danger, 0.12);
      }
    }

    &.success {
      background: rgba($success, 0.04);
      color: $success;
      span {
        color: $success;
      }

      &:hover {
        background: rgba($success, 0.08);
        color: darken($success, 10%);
        span {
          color: darken($success, 10%);
        }
      }

      &:active {
        background: rgba($success, 0.12);
      }
    }

    &.warning {
      background: rgba($warning, 0.1);
      color: $warning;
      span {
        color: $warning;
      }
    }

    &:disabled {
      background: rgba($gray-3, 0.04);
      color: $gray-4;
      cursor: not-allowed;
      transform: none;

      .material-symbols-rounded {
        transform: none;
      }
    }
    &.outline {
      background: transparent;
      border: 1px solid $gray-2;
      color: $gray-7;

      &:hover {
        background: rgba($primary, 0.04);
        border-color: $primary;
        color: $primary;
      }
    }
  }

  // Icon Button
  &.btn-icon {
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: map-get($border-radius, 'sm');
    background: rgba($purple-3, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;

    .material-symbols-rounded {
      font-size: 20px;
      color: $gray-6;
    }

    &:hover {
      background: rgba($purple-3, 0.1);
      transform: translateY(-1px);

      .material-symbols-rounded {
        color: $purple-4;
        transform: scale(1.1);
      }
    }

    &.primary {
      background: rgba($primary, 0.1);

      .material-symbols-rounded {
        color: $primary;
      }

      &:hover {
        background: rgba($primary, 0.15);
      }
    }

    &.danger {
      background: rgba($danger, 0.1);

      .material-symbols-rounded {
        color: $danger;
      }

      &:hover {
        background: rgba($danger, 0.15);
      }
    }

    &.success {
      background: rgba($success, 0.1);

      .material-symbols-rounded {
        color: $success;
      }

      &:hover {
        background: rgba($success, 0.15);
      }
    }
  }

  // Loading state
  &.loading {
    color: transparent;
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
      border: 2px solid rgba($white, 0.3);
      border-top-color: $white;
      border-radius: 50%;
      animation: button-loading-spinner 0.6s linear infinite;
    }
  }
}

// Size variations
.btn-sm {
  padding: map-get($spacing, 'xs') map-get($spacing, 'sm');
  font-size: map-get($font-sizes, 'xs');

  &.btn-icon {
    width: 28px;
    height: 28px;

    .material-symbols-rounded {
      font-size: 16px;
    }
  }
}

.btn-lg {
  padding: map-get($spacing, 'md') map-get($spacing, 'lg');
  font-size: map-get($font-sizes, 'lg');

  &.btn-icon {
    width: 44px;
    height: 44px;

    .material-symbols-rounded {
      font-size: 24px;
    }
  }
}

// Button groups
.btn-group {
  display: flex;
  gap: map-get($spacing, 'xs');
  align-items: center;
}

@keyframes button-loading-spinner {
  from { transform: rotate(0turn); }
  to { transform: rotate(1turn); }
}
