body {
  font-family: $font-family-base !important;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $color-text;
  background-color: $color-background;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  span, p, h1, h2, h3, h4, h5{
    color: $color-text;
  }

  h1, h2, h3, h4, h5, h6{
    font-family: 'Satoshi-Variable', sans-serif;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.material-symbols-rounded{
  font-variation-settings: 'wght' 300;
}


