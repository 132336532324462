@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

// Typography
$font-family-base: 'Nunito Sans', sans-serif;
$font-family-headings: 'Satoshi-Variable', sans-serif;
$font-size-base: 16px;
$line-height-base: 1.5;

// Color System

// Primary Colors
$purple-1: #dad6f1;
$purple-2: #a894e6;
$purple-3: #5527ad;
$purple-4: #241054;

// Neutral Colors
$white: #ffffff;
$black: #0c0c0c;

// Gray Scale
$gray-1: #f0f1f5;
$gray-2: #d2d4da;
$gray-3: #b3b5bd;
$gray-4: #9496a1;
$gray-5: #777986;
$gray-6: #5a5b5c;
$gray-7: #495057;

// Semantic Colors
$primary: $purple-3;
$secondary: $purple-4;
$success: #198754;
$info: $purple-2;
$warning: #ecb43d;
$danger: #dd284f;

// State Colors with Opacity Variants
$color-states: (
  'primary': (
    'base': $primary,
    'hover': darken($primary, 5%),
    'active': darken($primary, 10%),
    'light': rgba($primary, 0.1),
    'lighter': rgba($primary, 0.05)
  ),
  'success': (
    'base': $success,
    'hover': darken($success, 5%),
    'active': darken($success, 10%),
    'light': rgba($success, 0.1),
    'lighter': rgba($success, 0.05)
  ),
  'warning': (
    'base': $warning,
    'hover': darken($warning, 5%),
    'active': darken($warning, 10%),
    'light': rgba($warning, 0.1),
    'lighter': rgba($warning, 0.05)
  ),
  'danger': (
    'base': $danger,
    'hover': darken($danger, 5%),
    'active': darken($danger, 10%),
    'light': rgba($danger, 0.1),
    'lighter': rgba($danger, 0.05)
  ),
  'info': (
    'base': $info,
    'hover': darken($info, 5%),
    'active': darken($info, 10%),
    'light': rgba($info, 0.1),
    'lighter': rgba($info, 0.05)
  )
);

// Background Colors
$color-background: $gray-1;
$color-text: $gray-7;
$color-border: $gray-2;

// Gradients
$gradient-primary: linear-gradient(135deg,
  lighten($black, 6),
  darken($purple-3, 4)
);
$gradient-secondary: linear-gradient(135deg,
  $purple-1,
  $purple-2
);

// Spacing System
$spacing-unit: 8px;
$spacing: (
  'xs': $spacing-unit * 0.5,  // 4px
  'sm': $spacing-unit,        // 8px
  'md': $spacing-unit * 2,    // 16px
  'lg': $spacing-unit * 3,    // 24px
  'xl': $spacing-unit * 4,    // 32px
  '2xl': $spacing-unit * 5    // 40px
);

// Border Radius
$border-radius: (
  'xs': 4px,
  'sm': 8px,
  'md': 12px,
  'lg': 16px,
  'xl': 20px,
  'pill': 9999px
);

// Shadows
$box-shadow: (
  'xs': 0 1px 2px rgba($black, 0.05),
  'sm': 0 2px 4px rgba($black, 0.1),
  'md': 0 4px 8px rgba($black, 0.1),
  'lg': 0 8px 16px rgba($black, 0.1),
  'xl': 0 12px 24px rgba($black, 0.1)
);

// Transitions
$transition-duration: 0.2s;
$transition-timing: cubic-bezier(0.4, 0, 0.2, 1);

// Z-index
$z-index: (
  'dropdown': 1000,
  'sticky': 1020,
  'fixed': 1030,
  'modal-backdrop': 1040,
  'modal': 1050,
  'popover': 1060,
  'tooltip': 1070
);

// Material Design Progress Bar Overrides
:root {
  --mdc-linear-progress-track-height: 3px;
  --mdc-linear-progress-active-indicator-height: 3px;
  --mdc-linear-progress-buffer-height: 3px;
  --mdc-linear-progress-track-shape: 2px;
  --mdc-linear-progress-active-indicator-color: #{$purple-4};
}

// Typography Scale
$font-sizes: (
  'xs': 12px,
  'sm': 14px,
  'base': 16px,
  'lg': 18px,
  'xl': 20px,
  '2xl': 24px,
  '3xl': 30px,
  '4xl': 36px,
  '5xl': 48px
);

$font-weights: (
  'light': 300,
  'regular': 400,
  'medium': 500,
  'semibold': 600,
  'bold': 700,
  'black': 900
);

// Line Heights
$line-heights: (
  'tight': 1.2,
  'snug': 1.375,
  'normal': 1.5,
  'relaxed': 1.625,
  'loose': 2
);

// Container Max Widths
$container-max-widths: (
  'sm': 540px,
  'md': 720px,
  'lg': 960px,
  'xl': 1140px,
  '2xl': 1320px
);
