html,
body {
  height: 100%;
}

//scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #0000002f;
  border-radius: 4px;

  &:hover {
    background-color: #0000004f;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent
}

.mat-mdc-dialog-surface {
  border-radius: 12px !important;
  padding: 1.5rem;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
  &:has(.welcome-dialog){
    padding: 0 !important;
  }
}

.page-header {
  h2 {
    font-weight: 800;
    margin-bottom: 0 !important;
  }

  .subtitle {
    font-weight: 600;
    font-size: 18px;
    color: $gray-5;
  }
}

.wgt-card{
  background: white;
  border-radius: 15px;
  box-shadow: map-get($box-shadow, 'md');
}

.mdc-tab-indicator__content {
  border-width: 1px !important;
}

.mdc-tab__text-label {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  color: $gray-5 !important;
}

.mdc-tab--active {
  .mdc-tab__text-label {
    color: $purple-3 !important;
  }
}

.surface-p-0 {
  .mdc-dialog__surface{
    padding: 0 !important;
  }
}

.sidebar-dialog-content{
  height: 100%;
  border-top: 1px solid $gray-2;
  padding: 32px;
  overflow-y: auto;
}

.sidebar-backdrop{
  background-color: rgba(0, 0, 0, 0.1);
  top: 70px;
  &~* .mat-mdc-dialog-panel:not(.fw-alert-dialog):not(.inner-dialog){
    position: absolute !important;
    top: 70px;
    right: 0 !important;
    width: 100vw !important;
    height: calc(100vh - 70px);
    .mat-mdc-dialog-surface{
      border-radius: 0 !important;
      box-shadow: map-get($box-shadow, 'md') !important;
      animation: slideIn 0.5s ease-in-out;
      padding: 0 !important;
      z-index: 100 !important;
      height: 100% !important;
    }
  }

}

.os-card-dialog{
  width: calc(100dvw - 580px) !important;
  max-width: 100dvw !important;
  height: calc(100dvh - 70px);
  overflow-y: auto;
  border-radius: 0 !important;
  box-shadow: map-get($box-shadow, 'md') !important;
  animation: slideIn 0.5s ease-in-out;
  padding: 0 !important;
  top: 70px;
  right: 0 !important;
  position: absolute !important;
  min-width: min(650px, 100dvw) !important;
  .mat-mdc-dialog-surface{
    border-radius: 0 !important;
    box-shadow: map-get($box-shadow, 'md') !important;
    animation: slideIn 0.5s ease-in-out;
    padding: 0 !important;
    z-index: 100 !important;
    height: 100% !important;
    width: 100% !important;
   }
   &.fullscreen{
    width: calc(100dvw - 80px) !important;
    height: calc(100dvh - 70px) !important;

   }
}

.os-card-backdrop{
  background-color: rgba(0, 0, 0, 0.1);
  top: 70px;
}

@keyframes slideIn {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  20% {
    transform: translateX(100vw);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

audio{
  height: 20px;
  background-color: transparent;
}

.cursor-pointer{
  cursor: pointer;
}

.preview-block{
  &:not(.text, .button){
    border-radius: 8px;
    padding: 16px;
  }
  &.warning{
    background-color: rgba($warning, 0.1);
    color: $warning;
    border: 1px solid rgba($warning, 0.5);
  }
  &.error{
    background-color: rgba($danger, 0.1);
    color: $danger;
    border: 1px solid rgba($danger, 0.5);
  }
  &.success{
    background-color: rgba($success, 0.1);
    color: $success;
    border: 1px solid rgba($success, 0.5);
  }
  &.info{
    background-color: rgba($primary, 0.1);
    color: $primary;
    border: 1px solid rgba($primary, 0.5);
  }
  &.highlight{
    background-color: rgba($secondary, 0.1);
    color: $secondary;
    border: 1px solid rgba($secondary, 0.5);
  }
}
