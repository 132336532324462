/**
* @license
*
* Font Family: Satoshi
* Designed by: Deni Anggara
* URL: https://www.fontshare.com/fonts/satoshi
* © 2024 Indian Type Foundry
*
* Satoshi Light
* Satoshi LightItalic
* Satoshi Regular
* Satoshi Italic
* Satoshi Medium
* Satoshi MediumItalic
* Satoshi Bold
* Satoshi BoldItalic
* Satoshi Black
* Satoshi BlackItalic
* Satoshi Variable (Variable font)
* Satoshi VariableItalic (Variable font)

*
*/
@font-face {
  font-family: 'Satoshi-Light';
  src: url('/fonts/Satoshi-Light.woff2') format('woff2'),
       url('/fonts/Satoshi-Light.woff') format('woff'),
       url('/fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('/fonts/Satoshi-LightItalic.woff2') format('woff2'),
       url('/fonts/Satoshi-LightItalic.woff') format('woff'),
       url('/fonts/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Regular';
  src: url('/fonts/Satoshi-Regular.woff2') format('woff2'),
       url('/fonts/Satoshi-Regular.woff') format('woff'),
       url('/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-Italic';
  src: url('/fonts/Satoshi-Italic.woff2') format('woff2'),
       url('/fonts/Satoshi-Italic.woff') format('woff'),
       url('/fonts/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Medium';
  src: url('/fonts/Satoshi-Medium.woff2') format('woff2'),
       url('/fonts/Satoshi-Medium.woff') format('woff'),
       url('/fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('/fonts/Satoshi-MediumItalic.woff2') format('woff2'),
       url('/fonts/Satoshi-MediumItalic.woff') format('woff'),
       url('/fonts/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Variable';
  src: url('/fonts/Satoshi-Variable.woff2') format('woff2'),
       url('/fonts/Satoshi-Variable.woff') format('woff'),
       url('/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
       url('/fonts/Satoshi-VariableItalic.woff') format('woff'),
       url('/fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Black';
  src: url('/fonts/Satoshi-Black.woff2') format('woff2'),
       url('/fonts/Satoshi-Black.woff') format('woff'),
       url('/fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('/fonts/Satoshi-BlackItalic.woff2') format('woff2'),
       url('/fonts/Satoshi-BlackItalic.woff') format('woff'),
       url('/fonts/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 900.0;
*
* available axes:
'wght' (range from 300.0 to 900.0
*/
@font-face {
  font-family: 'Satoshi-Variable';
  src: url('/fonts/Satoshi-Variable.woff2') format('woff2'),
       url('/fonts/Satoshi-Variable.woff') format('woff'),
       url('/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 900.0;
*
* available axes:
'wght' (range from 300.0 to 900.0
*/
@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
       url('/fonts/Satoshi-VariableItalic.woff') format('woff'),
       url('/fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

